@import url('./assets/lib/hljs/styles/atom-one-light.css');
@import url('../node_modules/ng-zorro-antd/src/ng-zorro-antd.min.css');
@import url('../node_modules/@fortawesome/fontawesome-free/css/all.min.css');
@import './assets/scss/app.scss';
@import "../node_modules/flag-icons/css/flag-icons.min.css";


$mbsc-ios-accent: $brand-primary;
@import "../node_modules/@mobiscroll/angular/dist/css/mobiscroll.scss";

a {
  color: $brand-primary;

  &:hover {
    color: $gray-dark;
  }
}

.ant-typography a, a.ant-typography {
  color: $brand-primary;
}

.side-nav {
  z-index: 999;
}

.ant-menu-title-content>a {
  color: #53535f;
}

.ant-menu {
  li {
    &:hover {
      color: $brand-primary !important;
    }


  }

}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after {
  border-bottom: 2px solid $brand-primary !important;
}

.print-only {
  display: none;
}

@media print {
  #print-only {
    display: unset;
  }
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-bold {
  font-weight: bold;
}

.pull-right {
  float: right;
}

.color-red {
  color: #a10004;
}

.color-green {
  color: #217b00;
}

.color-orange {
  color: #ff8f00;
}

.color-time-orange {
  color: #ff964f;
}

.color-blue {
  color: #4382ff;
}

.color-light-grey {
  color: #c2c2c2;
}

.background-green {
  background-color: #EDF9E8;
}

.background-gray {
  background-color: rgba(210, 210, 210, 0.4);
}

.background-light-gray {
  background-color: rgba(210, 210, 210, 0.1);
}

.background-yellow {
  background-color: #fff9e3;
}

.background-red {
  background-color: #ffe3e3;
}

.background-light-red {
  background-color: rgba(255, 225, 225, 0.5);
}

.ticket-doUntil-alert {
  color: indianred;
  font-weight: bold;
}

.background-blue {
  background-color: #b2e6ff;
}

.row-active {
  background-color: rgba(255, 143, 0, 0.11);

  &:hover {
    td {
      background-color: rgba(255, 143, 0, 0.17) !important;
    }

  }
}

.ant-calendar-picker, .ant-calendar-picker-container {
  width: 100% !important;
}

.common-layout .page-header-tab .ant-tabs-nav, .ant-tabs-nav {
  margin-bottom: 1px;
  width: 100%;
}

.header .logo img {
  width: 100%;
  padding: 20px;

  &.logo-fold {
    height: 70px;
  }
}

.ant-select-multiple .ant-select-selector {
  min-height: 2.6625rem;
  height: auto !important;
}

.ant-upload.ant-upload-drag {
  padding: 0 !important;
}

.nav-title {
  padding: 10px;
  background-color: #f5f5f5;
}

.ant-anchor-ink:before {
  width: 0;
  background-color: transparent;
}

.ant-affix {
  z-index: 200;
}


.border-primary {
  .ant-select-selector {
    border-color: $brand-primary !important;
  }
}

.mbsc-ios.mbsc-textfield-wrapper-outline, .mbsc-ios.mbsc-textfield-wrapper-box {
  margin: 0;
}

.wordwrap {
  word-wrap: break-word;
}

.overflow-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: $brand-primary !important;
}

.ant-checkbox-inner {
  border: 1px solid #b3b5b7;
}

.card-no-padding {
  .ant-card-body {
    padding: 0;
  }
}

.permission-table table {
  width: auto !important;
}

.menu-license-expired a {
  &:not(.ignore-license) {
    cursor: not-allowed !important;
  }

}

.ant-select-item {
  border-left: 4px solid transparent;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  border-left: 4px solid $brand-primary;
}

.no-border > mbsc-popup {
  border: none;
}

.md-stripes-bg {
  background: repeating-linear-gradient(-45deg, #efefef, #efefef 10px, #ffffff 10px, #ffffff 20px);
}

.absent-stripes-bg {
  background: repeating-linear-gradient(-45deg, rgba(239, 239, 239, 0.69), rgba(239, 239, 239, 0.69) 10px, rgba(255, 255, 255, 0.69) 10px, rgba(255, 255, 255, 0.69) 20px) !important;
}

.cd-stripes-bg {
  background: repeating-linear-gradient(-45deg, rgba(239, 239, 239, 0.69), rgba(239, 239, 239, 0.69) 8px, rgba(255, 255, 255, 0.69) 8px, rgba(255, 255, 255, 0.69) 16px) !important;
}

.apsenceEvents {
}

.halfDayEvent {
  background: linear-gradient(
                  to left top,
                  transparent 50%,
                  #BADBAB 50%) no-repeat;
}

.cursor-pointer {
  cursor: pointer;
}

.small-checkbox {
  vertical-align: top;
  .ant-checkbox-inner {
    width: 15px;
    height: 15px;
    &:after {
      left: 0.15rem;
      top: 0.4rem;
    }
  }

  &.ant-checkbox-wrapper {
    margin-right: 3px;
  }
}

.ant-tabs-tab {
  nz-badge {
    &>span {
      margin-right: 10px;
    }
  }
}

button > img {
  max-height: 18px;
  vertical-align: baseline;
}

.mbsc-event-list .mbsc-event-day {
  background-color: #eeeeee !important;
}

textarea.internal-comment {
  background-color: #fcffdd;
}

.ant-input-affix-wrapper > .ant-input {
  border: none !important;
}

.ant-input:disabled, .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector  {
  background-color: #fcfcfc !important;
  color: inherit !important;
  cursor: not-allowed;
}

.lexware-icon {
  max-height: 13px;
  line-height: 0;
  text-align: center;
  vertical-align: -.125em;
}
.ant-tabs-tab-btn {

  &>a {
    color: #2a2a2a !important;
  }

}

.scrollbar-wrapper::-webkit-scrollbar {
  width: 6px;
  transition: all 0.5s ease-in-out;
}

.scrollbar-wrapper::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
}
.scrollbar-wrapper::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
}

.scrollbar-wrapper:hover::-webkit-scrollbar-track {
  background: #efefef;
}
.scrollbar-wrapper:hover::-webkit-scrollbar-thumb {
  background: #ccc;
}

.thumbnail {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 4/3 auto;
  height: 100%;
  cursor: pointer;
  transition: all 0.15s;
  display: inline-block;

  &:hover {
      transform: scale(0.97);
  }
}

.lightbox-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;

  .modal-content {
    width: 100%;
    height: 80%;
  }

  .full-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .controls-bar {
    width: 100%;
    height: 50px;
    padding: 4px 2%;
    background-color: rgba(0, 0, 0, 0.75);;
    display: flex;
    justify-content: end;

    .control-btn {
        color: white;
        padding: 6px;
        margin: 0 6px;
        font-size: 16px;
        line-height: 30px;
        cursor: pointer;
    }
    .control-btn:last-child {
        font-size: 28px;
    }
    .control-btn:hover {
        color: orange;
    }
  }
  .thumbnails {
    height: fit-content;
    width: 100%;
    background: rgba(0, 0, 0, 0.75);
    text-align: center;
    padding: 15px 5%;

    h4 {
        color: #dedede;
        margin-bottom: 16px;
        word-break: break-all;
    }

    .modal-thumbnail {
        width: 60px;
        height: 50px;
        object-fit: cover;
        margin: 0 6px;
        padding: 2px;
    }
    .modal-thumbnail:hover {
        cursor: pointer;
        opacity: 0.9;
    }
    .modal-thumbnail.selected {
        border: 1px orange solid;
    }
  }

  @media screen and (max-width: 768px) {
    // Styles applied for viewports up to 768px wide (mobile devices, in landscape & portrait)
    .controls-bar {
      padding: 4px;
    }
  }
}

.ant-popover-inner-content {
  width: auto;
}

.marker-pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: #f18f00;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -15px 0 0 -15px;

  .image {
    background-size: contain !important;
    width: 24px;
    height: 24px;
    margin: 3px 0 0 3px;
    background: #fff;
    position: absolute;
    border-radius: 50%;
    transform: rotate(45deg);
  }

}

// to align icon
.map-div-icon svg {
  color: #FFFFFF;
  position: absolute;
  width: 18px;
  font-size: 18px;
  left: 0;
  right: 0;
  margin: 10px auto;
  text-align: center;
}

cdk-virtual-scroll-viewport {
  max-height: 60vh !important;
}

.ant-checkbox-inner:after {
  left: 5px;
}

div.fixed-footer {
  position: fixed;
  background-color: $body-bg;
  border-top: 2px solid $border-color;
  padding: 10px 20px;
  width: 100%;
  bottom: 0;
  left: $side-nav-width;
}

.is-folded {
  div.fixed-footer {
    left: $side-nav-folded;
  }
}

.checkbox-label {
  height: 0 !important;
}

$all-day-sizes: (
  xxl: 200px,
  xl: 100px
);

@each $size, $height in $all-day-sizes {
  .all-day-#{$size} {
    .mbsc-schedule-all-day,
    .mbsc-schedule-all-day-wrapper {
      height: $height;
      max-height: $height !important;
      overflow: hidden;
    }

    .mbsc-schedule-all-day-group-wrapper, .mbsc-schedule-header-wrapper {
      overflow-y: auto;
    }
  }
}

quill-editor {
  word-break: break-word;
}
